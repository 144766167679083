<template>
    <div>
        <layout-header title=""></layout-header>
        <input v-show="LOCAL" id="deviceToken" placeholder="장치 토큰" readonly="readonly" type="text">
    </div>
</template>

<script>
import LayoutHeader from "@/pages/layouts/layoutHeader";
import $ from "jquery";

export default {
    name: "naverReturn",
    components: {LayoutHeader},
    data() {

        const ENV = this.commonUtils.getEnv()

        const LOCAL = this.commonUtils.isLocal()

        return {

            BASE_URL: this.APIs.BASE_URL,

            NAVER_LOGIN_KEY: this.APIs.NAVER_LOGIN_KEY,
            NAVER_LOGIN_RETURN: this.APIs.NAVER_LOGIN_RETURN,

            ENV,
            deviceToken: '',
            LOCAL

        }
    },
    mounted() {

        this.$loadScript("https://static.nid.naver.com/js/naverLogin_implicit-1.0.3.js")
            .then(() => {
                this.commonUtils.localLog("Naver login api in loaded")
                this.login()
            })
            .catch(() => {
                this.commonUtils.localErr("Naver login api in not loaded")
            })

        this.commonUtils.deviceToken('#deviceToken')

    },
    methods: {

        goReferrer() {
            if (!this.stringUtils.isEmpty(this.$route.query.referrer)) {
                location.href = this.$route.query.referrer
            } else {
                location.href = "/"
            }
        },

        login() {

            const self = this

            let naver_id_login = new window.naver_id_login(self.NAVER_LOGIN_KEY, self.NAVER_LOGIN_RETURN)
            const NAVER_ACCESS_TOKEN = naver_id_login.oauthParams.access_token


            $.ajax({
                url: "https://openapi.naver.com/v1/nid/getUserProfile.json?response_type=json",

                type: "GET",

                data: {"access_token": NAVER_ACCESS_TOKEN},

                dataType: "jsonp",

                jsonp: "oauth_callback",

                success: function (res) {

                    let snsId
                    let email

                    try {
                        snsId = res.response.id
                        email = res.response.email
                    } catch (e) {
                        self.commonUtils.localWarn(e)
                    }

                    self.commonUtils.localWarn("STATUS ::: " + res.message, "snsId : " + snsId, "email : " + email)

                    self.axiosCaller.get(self, self.APIs.SESSION + '/doSnsLogin', {
                            snsService: 'naver',
                            snsId: snsId,
                            email: email,
                            deviceToken: (self.webUtils.isDom('#deviceToken')) ? document.querySelector('#deviceToken').value : "",
                        }, async (res) => {

                            const result = res.data

                            if (result.status == 'duplicate') {

                                self.swalUtils.gritter("이미 가입된 계정입니다.", result.service + "으로 로그인 해 주세요.", "warning", 3000)
                                    .then(t => {
                                        self.$router.push({
                                            name: "snsJoin"
                                        })
                                    })

                            } else if (!result.coAccountVO.isUse) {
								self.swalUtils.fire("탈퇴된 계정입니다.", "관리자에게 문의바랍니다.", "info", null, null).then(
									r => {
										self.$router.push({
											name: "snsJoin"
										})
									}
								)
							} else {

                                if (await self.setMainSessionProc(result.coAccountVO)) {
                                    self.setMainSessionKeep(self.isKeepSession)

                                    await self.$router.push({
                                        name: "profile"
                                    })

                                }
                            }

                        },
                        (e) => {
                            self.commonUtils.localErr(e)
                        })
                },
                fail: function (e) {
                    self.commonUtils.localErr(e)
                }
            })

        }

    }

}
</script>

<style scoped>

</style>